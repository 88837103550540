import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useModal } from 'mui-modal-provider';
import { ClassNameMap } from '@mui/styles';

import { Item, ProgramItem } from '../../program/program.model';
import { Button } from '../../../../common/button-new';
import { InternalLinks } from '../../../../../constants/internal-links';
import { AskLoginLink } from '../ask-login-link';
import { ContactAJnjProModal } from '../../../../common/contact-a-jnj-pro-modal';
import { useGoBack } from '../../../../../hooks/use-go-back';

export const useProgramItemsContent = () => {
  const intl = useIntl();
  const { showModal } = useModal();
  const { hasPreviousLocationState } = useGoBack();

  const handleAskQuestionClick = useCallback(() => {
    showModal(ContactAJnjProModal, {});
  }, [showModal]);

  const getProgramItemContent = useCallback((id: string, classes: ClassNameMap): ReactNode => intl.formatMessage(
    { id },
    {
      linebreak: <br />,
      sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
      p: (chunks: ReactNode) => <p className={classes.paragraph}>{chunks}</p>,
      myAcuvueProgramPolicyLink: (chunks: ReactNode) => (
        <a
          href={intl.formatMessage({ id: 'common.myAcuvuePolicy' })}
          className={classes.link}
          target="_blank"
        >
          {chunks}
        </a>
      ),
      myLensesLink: (chunks: ReactNode) => (
        <AskLoginLink
          to={InternalLinks.myLensesPage}
          state={hasPreviousLocationState}
          className={classes.link}
        >
          {chunks}
        </AskLoginLink>
      ),
      ecpLocatorLink: (chunks: ReactNode) => (
        <Button
          to={InternalLinks.ecpLocator}
          labelClassName="normal"
          style="text"
        >
          {chunks}
        </Button>
      ),
      newOrderLink: (chunks: ReactNode) => (
        <Button
          labelClassName="normal"
          onClick={handleAskQuestionClick}
          style="text"
        >
          {chunks}
        </Button>
      ),
    },
  ), [handleAskQuestionClick]);

  const getProgramItems = useCallback((
    config: Item[], classes: ClassNameMap,
  ): ProgramItem[] => config.map((configItem: Item) => ({
    title: intl.formatMessage({ id: configItem.title },
      {
        sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
      }),
    content: getProgramItemContent(configItem.content, classes),
    icon: <img src={configItem.icon} alt="" />,
  })), [getProgramItemContent]);

  const getProgramItemsContent = useCallback((
    items: Item[],
    classes: ClassNameMap,
  ) => (getProgramItems(items, classes)), [getProgramItems]);

  return { getProgramItemsContent };
};
