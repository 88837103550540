import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ellipse from '../../../../assets/images/backgrounds/ellipse.png';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  fontSizes,
  lineHeights,
  palette,
  fontWeights,
}: Theme) => ({
  root: {
    paddingTop: 24,
    paddingBottom: 32,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: -330,
    backgroundImage: `url(${ellipse})`,
    [breakpoints.up('sm')]: {
      paddingTop: 32,
      paddingBottom: 64,
      backgroundPositionY: -340,
    },
    [breakpoints.up('lg')]: {
      paddingTop: 40,
      backgroundPositionY: -270,
    },
  },
  title: {
    margin: '0 0 24px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights.lg,
    color: palette.text.secondary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxxl,
    },
    [breakpoints.up('lg')]: {
      margin: '0 0 32px',
    },
  },
  cards: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  card: {
    [breakpoints.up('md')]: {
      width: 'calc((100% - 1.5rem) / 2)',
    },
  },
}));
