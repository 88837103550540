import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontWeights,
  spaceMin,
  siteMediumWidth,
}: Theme) => ({
  root: {
    width: '100%',
    padding: `2rem ${spaceMin}`,
    overflow: 'hidden',
    background: `linear-gradient(270deg, ${palette.primary.light} 0%, ${palette.primary.dark} 35.5%, ${palette.primary.dark} 65%, ${palette.primary.light} 100%)`,
    [breakpoints.up('md')]: {
      marginBottom: '2rem',
    },
    [breakpoints.up(1181)]: {
      marginTop: 0,
    },
  },
  inner: {
    [breakpoints.up('md')]: {
      position: 'relative',
      display: 'flex',
      gap: spaceMin,
      maxWidth: siteMediumWidth,
      margin: '0 auto',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    [breakpoints.up('md')]: {
      width: `calc((100% - ${spaceMin}) / 2)`,
      position: 'relative',
      flexGrow: 'unset',
    },
  },
  title: {
    maxWidth: '18.75rem',
    fontFamily: fontFamilies.primary,
    fontSize: '1.25rem',
    fontWeight: fontWeights.medium,
    lineHeight: 1.4,
    textAlign: 'left',
    color: palette.common.white,
    marginTop: 0,
    marginBottom: spaceMin,
    [breakpoints.up('md')]: {
      maxWidth: '22rem',
      fontSize: '1.75rem',
    },
    [breakpoints.up('xl')]: {
      fontSize: '2.25rem',
      maxWidth: '34.375rem',
      lineHeight: 1.33,
    },
    '& sup': {
      position: 'relative',
      lineHeight: 0,
    },
  },
  newLine: {
    [breakpoints.between('md', 'xl')]: {
      display: 'block',
    },
  },
  image: {
    zIndex: 0,
    display: 'flex',
    width: '100%',
    [breakpoints.up('md')]: {
      position: 'relative',
      width: `calc((100% - ${spaceMin}) / 2)`,
      marginBottom: '-2rem',
    },
    [breakpoints.up('xl')]: {
      position: 'absolute',
      left: 'calc(50% + 1.5rem)',
      bottom: 0,
      width: 'unset',
    },
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      [breakpoints.up('xl')]: {
        width: 'auto',
        maxWidth: 'unset',
        height: '336px',
      },
    },
  },
}));
