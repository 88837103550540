import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IAskLoginModalProps } from './ask-login-modal.models';
import { useStyles } from './ask-login-modal.styles';
import { Modal, ResponsiveWidth } from '../modal';
import { Button } from '../button';
import { useGoBack } from '../../../hooks/use-go-back';
import { InternalLinks } from '../../../constants';
import { setRedirectUrl } from '../../../utils/redirect/redirect';
import { useLocation } from 'react-router-dom';

export const AskLoginModal = ({
  title, description, ...props
}: IAskLoginModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();
  const location = useLocation();

  return (
    <Modal
      {...props}
      data-testid="ask-login-modal"
      responsiveWidth={ResponsiveWidth.lg}
      propsPaperStyles={classes.propsPaperStyles}
      propsContentStyles={classes.propsContentStyles}
      content={(
        <>
          <h2 className={classes.title}>
            {intl.formatMessage(
              { id: title || 'myAcuvueProgram.registration.popup.text' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </h2>
          <p className={classes.description}>
            {intl.formatMessage(
              { id: description },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </p>
        </>
      )}
      footer={(
        <div className={classes.actions}>
          <Button
            variant="contained"
            to={InternalLinks.registration}
            state={hasPreviousLocationState}
            className={classes.register}
            onClick={() => setRedirectUrl(location.pathname)}
            data-testid="ask-login-register"
          >
            <FormattedMessage
              id="myAcuvueProgram.registration.popup.registerButton.label"
            />
          </Button>
          <Button
            variant="outlined"
            to={InternalLinks.login}
            state={hasPreviousLocationState}
            className={classes.login}
            onClick={() => setRedirectUrl(location.pathname)}
            data-testid="ask-login-login"
          >
            <FormattedMessage
              id="myAcuvueProgram.registration.popup.loginButton.label"
            />
          </Button>
        </div>
      )}
    />
  );
};
