import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWrapper } from '../../../common/content-wrapper';
import { useStyles } from './program.styles';
import { BackButton } from '../../../common/back-button';
import { Item } from './items';
import { Separator } from '../../../common';
import { DownloadApp } from '../download-app';
import { useProgramItemsContent } from './items';
import { ProgramCard } from './program-card/program-card';
import rubleIcon from '../../../../assets/images/gradient-round/ruble.svg';
import perCentIcon from '../../../../assets/images/gradient-round/per-cent.svg';
import locationIcon from '../../../../assets/images/gradient-round/location.svg';
import eyeIcon from '../../../../assets/images/gradient-round/eye-checked.svg';
import lensesIcon from '../../../../assets/images/gradient-round/lenses.svg';
import questionIcon from '../../../../assets/images/gradient-round/question.svg';

const items: Item[] = [
  {
    title: 'myAcuvueProgram.getPoints.title',
    content: 'myAcuvueProgram.getPoints.text',
    icon: rubleIcon,
  },
  {
    title: 'myAcuvueProgram.spendPoints.title',
    content: 'myAcuvueProgram.spendPoints.text',
    icon: perCentIcon,
  },
  { title: 'myAcuvueProgram.myLenses.title', content: 'myAcuvueProgram.myLenses.text', icon: locationIcon },
  { title: 'myAcuvueProgram.buyLenses.title', content: 'myAcuvueProgram.buyLenses.text', icon: eyeIcon },
  { title: 'myAcuvueProgram.fitLenses.title', content: 'myAcuvueProgram.fitLenses.text', icon: lensesIcon },
  { title: 'myAcuvueProgram.askExpert.title', content: 'myAcuvueProgram.askExpert.text', icon: questionIcon },
];

export const Program = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const { getProgramItemsContent } = useProgramItemsContent();
  const list = getProgramItemsContent(items, classes);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'myAcuvueProgramPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'myAcuvueProgramPage.description' })}
        />
      </Helmet>
      <div className={classes.root}>
        <ContentWrapper>
          <BackButton />
          <h1 className={classes.title}>
            <FormattedMessage
              id="myAcuvueProgram.title"
            />
          </h1>
        </ContentWrapper>
        <Separator />
        <ContentWrapper>
          <ul className={classes.cards}>
            {list.map((item, index) => (
              <li className={classes.card} key={index}>
                <ProgramCard
                  title={item.title}
                  content={item.content}
                  icon={item.icon}
                />
              </li>
            ))}
          </ul>
        </ContentWrapper>
        <Separator />
        <DownloadApp />
      </div>
    </>
  );
};
