import { useStyles } from './program-card.styles';
import { ProgramItem } from '../items';

export const ProgramCard = ({ title, content, icon }: ProgramItem): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
       <div className={classes.icon}>{icon}</div>
        <h2 className={classes.title}>{title}</h2>
        <div>
          {content}
        </div>
      </div>
    </>
  );
};
