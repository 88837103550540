import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import checked from '../../../../assets/images/icons/checked/checked-gradient.svg';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  fontWeights,
  siteMediumWidth,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    width: '100%',
    maxWidth: siteMediumWidth,
    padding: '2rem 0 0',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      margin: '0 auto',
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.medium,
    lineHeight: 1.4,
    color: palette.text.primary,
    marginTop: 0,
    marginBottom: '1.5rem',
    [breakpoints.up('md')]: {
      fontSize: '1.75rem',
      lineHeight: 1.35,
    },
  },
  left: {
    [breakpoints.up('md')]: {
      width: 'calc((100% - 1.5rem) / 2)',
    },
  },
  right: {
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'block',
      width: 'calc((100% - 1.5rem) / 2)',
    },
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  item: {
    minHeight: '1.5rem',
    paddingLeft: '2.25rem',
    background: `no-repeat url(${checked}) left top`,
    backgroundSize: '1.5rem',
    fontSize: fontSizes.sm,
    lineHeight: 1.5,
    '&:not(:last-child)': {
      marginBottom: '0.75rem',
    },
  },
  description: {
    marginTop: '1.5rem',
    marginBottom: '0.75rem',
    fontSize: fontSizes.sm,
  },
}));
