import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, spacing, palette, mediumWithPaddings }: Theme) => ({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 1, // to make boxshadow visible on the map
    boxShadow: '3px 4px 13px 0 rgba(5, 41, 103, 0.1)',
    background: palette.common.white,
  },
  containerLoggedIn: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    maxWidth: mediumWithPaddings,
    height: 80,
    margin: '0 auto',
    padding: '0 1.5rem',
    [breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      gap: '1.5rem',
    },
  },
  primaryNavContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: '1.5rem',
  },
  secondaryNavContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: spacing(1.5),
    [breakpoints.up('md')]: {
      gap: spacing(2.5),
    },
    [breakpoints.up('lg')]: {
      gap: spacing(4),
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
  },
  sup: {
    position: 'relative',
    top: '-0.4em',
  },
  mapLink: {
    [breakpoints.up('md')]: {
      maxWidth: '13.125rem',
    },
    [breakpoints.up('xl')]: {
      maxWidth: 'unset',
    },
    '&.active-link': {
      [breakpoints.up('md')]: {
        maxWidth: '13.5rem',
      },
      [breakpoints.up('xl')]: {
        maxWidth: 'unset',
      },
    },
  },
  mapText: {
    [breakpoints.up('md')]: {
      width: 'calc(100% - 2.125rem)',
    },
  },
  mapIcon: {
    width: '1.375rem',
    height: 'auto',
    [breakpoints.up('md')]: {
      marginRight: '0.75rem',
    },
  },
  userLink: {
    [breakpoints.up('md')]: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  },
  userIcon: {
    width: '1.75rem',
    height: '1.75rem',
    [breakpoints.up('lg')]: {
      marginRight: '0.75rem',
    },
  },
  infoIcon: {
    width: '1.625rem',
    height: '1.625rem',
    [breakpoints.up('md')]: {
      marginRight: '0.75rem',
    },
  },
}));
